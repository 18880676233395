<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card>
          <form-wizard
            color="#7367F0"
            :title="null"
            subtitle="Agrega tu tienda"
            finish-button-text="Confirmar"
            next-button-text="Siguiente"
            back-button-text="Regresar"
            class="mb-3 wizard-vertical"
            step-size="sm"
            layout="horizontal"
            @on-complete="handleSubmit"
          >
            <tab-content
              title="Informacion de la tienda"
              :before-change="validateStoreInfo"
            >
              <validation-observer ref="storeInfoRules" tag="form">
                <h2>Agrega tu tienda</h2>
                <div class="d-flex justify-content-start my-2">
                  <base-cropper
                    :model="store"
                    @cropped-image="store.logo = $event"
                  />
                </div>
                <b-form-group class="mb-2" label="Nombre de la tienda">
                  <validation-provider
                    #default="{ errors }"
                    name="Nombre"
                    rules="required"
                  >
                    <b-form-input v-model="store.name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  class="mb-2"
                  label="¿Vendes en linea o en tu tienda fisica?"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Categoria de tienda"
                    rules="required"
                  >
                    <v-select
                      v-model="store.category"
                      :options="storeCategory"
                      :reduce="(val) => val.value"
                      label="text"
                      clearable
                      placeholder="Selecciona la categoria de tienda"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mb-2" label="Tipo de tienda">
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de tienda"
                    rules="required"
                  >
                    <v-select
                      v-model="store.store_type"
                      :options="storeType"
                      :reduce="(val) => val.value"
                      label="text"
                      clearable
                      placeholder="Selecciona el tipo de tienda"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="mb-2" label="¿A qué hora abres la tienda?">
                  <validation-provider
                    #default="{ errors, valid }"
                    name="Hora de apertura"
                    rules="required"
                  >
                    <v-select
                      v-model="store.start_of_day_restocking_time"
                      :options="timeArray"
                      :reduce="(val) => val.type"
                      label="name"
                      clearable
                      placeholder="Selecciona el mejor horario para ti"
                      :state="errors.length > 0 ? false : valid ? true : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  class="mb-2"
                  label="¿A qué hora cierras la tienda?"
                >
                  <validation-provider
                    #default="{ errors, valid }"
                    name="Hora de cierre"
                    rules="required"
                  >
                    <v-select
                      v-model="store.end_of_day_restocking_time"
                      :options="timeArray"
                      :reduce="(val) => val.type"
                      label="name"
                      clearable
                      placeholder="Selecciona el mejor horario para ti"
                      :state="errors.length > 0 ? false : valid ? true : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="
                    (store.category === 'online' ||
                      store.category === 'hybrid') &&
                    (acceptedPaymentTypes.includes('walleat') ||
                      acceptedPaymentTypes.includes('reward_points'))
                  "
                  class="mb-2"
                  label="Mensaje de cobro para ventas en linea"
                >
                  <b-form-input v-model="store.codi_message" required />
                </b-form-group>
              </validation-observer>
            </tab-content>
            <tab-content
              title="Metodos de pago"
              :before-change="validateDeliveryOption"
            >
              <validation-observer ref="storeDeliveryRules" tag="form">
                <h2 class="mb-2">Información de envio y pago</h2>
                <b-form-group
                  label="Selecciona el metodo de envio que acepta esta tienda"
                  label-for="is-edible"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de envio"
                    rules="required"
                  >
                    <v-select
                      v-model="store.delivery_option"
                      :options="deliveryOptions"
                      :reduce="(val) => val.value"
                      label="text"
                      clearable
                      placeholder="Selecciona el tipo de envio"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Selecciona el tipo de envio"
                  label-for="is-edible"
                  v-if="
                    store.delivery_option === 'delivery' ||
                    store.delivery_option === 'both'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de envio"
                    rules="required"
                  >
                    <b-form-radio-group
                      id="delivery-type"
                      stacked
                      v-model="store.delivery_type"
                      class="custom-control-primary mb-50"
                      :options="deliveryTypes"
                      name="delivery-type"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-observer>

              <b-form-group
                label="Selecciona los tipos de pago que acepta esta tienda"
                label-for="is-edible"
              >
                <validation-observer
                  ref="storePaymentRules"
                  tag="form"
                  v-slot="{ invalid }"
                >
                  <b-form-checkbox
                    id="cash"
                    v-model="acceptedPaymentTypes"
                    class="custom-control-primary mb-50 mt-1"
                    name="check-button"
                    value="cash"
                    switch
                  >
                    Efectivo
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="walleat"
                    v-model="acceptedPaymentTypes"
                    class="custom-control-primary mb-50"
                    name="check-button"
                    value="walleat"
                    switch
                  >
                    Walleat Pay
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="bankcard"
                    v-model="acceptedPaymentTypes"
                    class="custom-control-primary mb-50"
                    name="check-button"
                    value="bankcard"
                    switch
                  >
                    Tarjeta bancaria
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="reward_points"
                    v-model="acceptedPaymentTypes"
                    class="custom-control-primary mb-50"
                    name="check-button"
                    value="reward_points"
                    switch
                  >
                    Puntos de recompensa
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="bank_deposit"
                    v-model="acceptedPaymentTypes"
                    class="custom-control-primary mb-50"
                    name="check-button"
                    value="bank_deposit"
                    switch
                  >
                    Transferencia o depósito bancario
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="codi"
                    v-model="acceptedPaymentTypes"
                    class="custom-control-primary"
                    name="check-button"
                    value="codi"
                    switch
                  >
                    CoDi
                  </b-form-checkbox>
                </validation-observer>
              </b-form-group>
            </tab-content>
            <tab-content title="Direccion de la tienda">
              <h2 class="mb-2">Ubicación de la tienda</h2>
              <gmap-autocomplete
                ref="googleAddress"
                class="mb-2"
                placeholder="Buscar dirección"
                :componentRestrictions="{ country: 'mx' }"
                @place_changed="setAddressForEdit"
              />

              <b-form-group
                label="Radio de entrega (km)"
                label-for="radius"
                v-if="store.address_attributes.lat && showLastStep"
              >
                <div
                  class="d-flex mb-2 justify-content-center align-items-center"
                >
                  <b-button
                    v-ripple
                    variant="primary"
                    class="mr-1 radius__button"
                    @click="decrementCircleRadius"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>

                  <b-form-input
                    v-model="circleRadiusKm"
                    type="range"
                    size="lg"
                    :max="30"
                    :min="1"
                    :step="1"
                    @input="updateCircleRadiusFromSlider"
                  ></b-form-input>

                  <b-button
                    v-ripple
                    variant="primary"
                    class="ml-1 radius__button"
                    @click="incrementCircleRadius"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
                <p class="text-center">{{ circleRadiusKm }} km</p>
              </b-form-group>

              <GmapMap
                v-if="showLastStep"
                ref="map"
                :center="center"
                :zoom="zoom"
                :style="`width: ${width}; height: ${height}`"
                @click="setMarker($event)"
                map-type-id="roadmap"
                :options="{
                  streetViewControl: false,
                  fullscreenControl: false,
                  restriction: {
                    latLngBounds: {
                      north: 32.718,
                      south: 14.532,
                      west: -118.503,
                      east: -86.589,
                    },
                    strictBounds: false,
                  },
                }"
              >
                <GmapMarker :position="marker" :clickable="true" />
                <GmapCircle
                  v-if="circle.center"
                  :center="circle.center"
                  :radius="circle.radius"
                  :editable="false"
                  :draggable="false"
                  :fillColor="`#FF0000`"
                  :fillOpacity="0.3"
                  @radius_changed="updateCircleRadius"
                  @center_changed="updateCircleCenter"
                />
              </GmapMap>
              <GmapMap
                v-else
                ref="map"
                :center="center"
                :zoom="zoom"
                :style="`width: ${width}; height: ${height}`"
                map-type-id="roadmap"
                :clickable="true"
                :options="{
                  streetViewControl: false,
                  fullscreenControl: true,
                  restriction: {
                    latLngBounds: {
                      north: 32.718,
                      south: 14.532,
                      west: -118.503,
                      east: -86.589,
                    },
                    strictBounds: false,
                  },
                }"
              >
                <GmapMarker :position="marker" :clickable="true" />
              </GmapMap>

              <small v-if="!store.address_attributes.lat" class="text-danger">
                Selecciona una dirección
              </small>
            </tab-content>
          </form-wizard>
        </b-card>
      </b-col>
      <b-col class="d-none d-md-block">
        <div class="text-center">
          <b-img
            :src="
              require('@/assets/images/illustration/pricing-Illustration.svg')
            "
            width="600"
            fluid
          />
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModal"
      no-close-on-backdrop
      hide-footer
      hide-header
      no-close-on-esc
    >
      <validation-observer ref="aliasRules" v-slot="{ invalid }">
        <h4 v-if="!establishmentAccounts.length" class="text-center">
          Ingresa los datos para la nueva cuenta de Stripe
        </h4>
        <h4 v-if="establishmentAccounts.length" class="text-center">
          Selecciona una de las cuenta de Stripe
        </h4>
        <b-form class="mt-2" @submit.prevent="handleStripeAccount()">
          <b-form-group
            label="Alias"
            label-for="alias"
            v-if="!establishmentAccounts.length"
          >
            <validation-provider
              #default="{ errors }"
              name="alias"
              rules="required"
            >
              <b-form-input
                v-model="aliasAccount"
                :state="errors.length > 0 ? false : null"
                placeholder="Alias"
                name="alias"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Tipo de negocio"
            label-for="business-type"
            v-if="!establishmentAccounts.length"
          >
            <validation-provider
              #default="{ errors }"
              name="business-type"
              rules="required"
            >
              <v-select
                v-model="businessType"
                :options="[
                  { value: 'individual', text: 'Individual' },
                  { value: 'company', text: 'Empresa' },
                ]"
                :state="errors.length > 0 ? false : null"
                label="text"
                clearable
                placeholder="Tipo de negocio"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Cuentas Disponibles"
            label-for="alias"
            v-if="establishmentAccounts.length"
          >
            <validation-provider
              #default="{ errors }"
              name="alias"
              rules="required"
            >
              <v-select
                v-model="aliasAccount"
                :options="establishmentAccounts"
                :state="errors.length > 0 ? false : null"
                label="alias"
                clearable
                :reduce="(val) => val.id"
                placeholder="Selecciona una cuenta para la tienda"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              @click="handleStripeAccount()"
              :disabled="invalid"
              class="mr-1"
            >
              Agregar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-container>
</template>

<script>
import vSelect from "vue-select"
import { FormWizard, TabContent } from "vue-form-wizard"
import { mapActions, mapGetters } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import BaseCropper from "@/@core/components/BaseCropper.vue"

import { required } from "@validations"

import "vue-form-wizard/dist/vue-form-wizard.min.css"
import store from "@/store"
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue"
import VueGoogleAutocomplete from "vue-google-autocomplete"
import { getGoogleMapsAPI } from "gmap-vue"

import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  components: {
    FormWizard,
    TabContent,
    VueGoogleAutocomplete,
    BaseCropper,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      acceptedPaymentTypes: [],
      timeArray: [],
      store: {
        establishment_id: this.$route.params.id,
        name: null,
        logo: null,
        category: null,
        store_type: null,
        codi_message: null,
        start_of_day_restocking_time: null,
        end_of_day_restocking_time: null,
        accepted_payment_types: [],
        address_attributes: {},
        delivery_option: null,
        delivery_radius: null,
        delivery_type: "local",
      },
      storeCategory: [
        { value: "physical", text: "Tienda fisica" },
        { value: "online", text: "Tienda en línea" },
        { value: "hybrid", text: "Tienda híbrida" },
      ],
      deliveryOptions: [
        { value: "pickup", text: "Pick up" },
        { value: "delivery", text: "Delivery" },
        { value: "both", text: "Ambos" },
        { value: "not_applicable", text: "No aplica" },
      ],
      deliveryTypes: [
        { text: "Local", value: "local" },
        { text: "Nacional", value: "national" },
      ],
      storeType: [
      { value: "warehouse", text: "Almacén" },
        { value: "car_accessories", text: "Accesorios para autos" },
        { value: "coffee_shop", text: "Cafetería" },
        { value: "school_cafeteria", text: "Cafetería de la escuela" },
        { value: "butchery", text: "Carniceria" },
        { value: "locksmith", text: "Cerrajería" },
        { value: "corporate_education", text: "Educación corporativa" },
        { value: "drug_store", text: "Farmacia" },
        { value: "hardware_store", text: "Ferretería" },
        { value: "flower_store", text: "Floreria" },
        { value: "gym", text: "Gimnasio" },
        { value: "health_food_store", text: "Tienda de alimentos saludables" },
        { value: "home_goods_store", text: "Tienda de artículos para el hogar" },
        { value: "jewelry_store", text: "Joyería" },
        { value: "car_wash", text: "Lavado de autos" },
        { value: "car_tires", text: "Llantas y rines" },
        { value: "book_store", text: "Librería" },
        { value: "liquor_store", text: "Licorería" },
        { value: "lumber_store", text: "Maderería" },
        { value: "music_store", text: "Tienda de música" },
        { value: "optician", text: "Óptica" },
        { value: "outdoor_furniture_store", text: "Muebles de jardín" },
        { value: "paint_store", text: "Tienda de pinturas" },
        { value: "pet_store", text: "Tienda de mascotas" },
        { value: "cleaning_hardware", text: "Productos de limpieza" },
        { value: "water_purifier", text: "Purificadora de agua" },
        { value: "car_parts", text: "Refaccionaria" },
        { value: "corner_shop", text: "Tiendita de la esquina" },
        { value: "clothing_store", text: "Tienda de ropa" },
        { value: "furniture_store", text: "Mueblería" },
        { value: "tortilleria", text: "Tortillería" },
        { value: "car_sales", text: "Venta de autos" },
        { value: "other", text: "Otra" },
      ],
      showModal: false,
      aliasAccount: null,
      businessType: null,
      storeAccount: null,
      center: { lat: 19.4326077, lng: -99.133208 },
      zoom: 4,
      width: "100%",
      height: "500px",
      marker: {},
      circle: {
        center: null,
        radius: 1000,
      },
      circleRadiusKm: 1,
    }
  },
  mounted() {
    this.generateTimeArray()
  },
  computed: {
    ...mapGetters("stripe", ["establishmentAccounts"]),

    showLastStep() {
      return (
        (this.store.delivery_option === "delivery" ||
          this.store.delivery_option === "both") &&
        this.store.delivery_type === "local"
      )
    },
  },
  methods: {
    ...mapActions("stores", ["addStore", "editStore", "fetchStore"]),
    ...mapActions("stripe", [
      "createEstablishmentAccount",
      "fetchEstablishmentAccounts",
      "createStoreAccount",
      "fetchEstablishmentAccountLink",
    ]),
    setMarker(event) {
      const mapsapi = new getGoogleMapsAPI()

      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      mapsapi.maps.Geocoder.prototype.geocode(
        { location: latlng },
        (results, status) => {
          if (status === "OK") {
            if (results[1]) {
              this.$refs.googleAddress.$el.children[0].value =
                results[1].formatted_address
              this.address = this.getAddress(results[1])
              const lat = event.latLng.lat()
              const lng = event.latLng.lng()
              this.address.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
              this.marker = {
                lat,
                lng,
              }
              this.circle.center = this.marker
              this.circle.radius = this.circleRadiusKm * 1000
              this.$emit("setAddress", this.address)
              this.zoom = 8

              this.store.address_attributes = this.address
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Introduzca la direccion manualmente",
                  icon: "EditIcon",
                  variant: "danger",
                },
              })
            }
          }
        }
      )
    },
    setAddressForEdit(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.zoom = 16
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.circle.center = this.marker
      this.store.address_attributes = this.getAddress(place)
    },
    updateCircleRadius() {
      if (this.circle) {
        const circleInstance = this.$refs.circle.$circleObject
        this.circle.radius = circleInstance.getRadius()
      }
    },
    decrementCircleRadius() {
      if (this.circleRadiusKm > 1) {
        this.circleRadiusKm = this.circleRadiusKm - 1
        this.circle.radius = this.circleRadiusKm * 1000
      }
    },
    incrementCircleRadius() {
      if (this.circleRadiusKm < 30) {
        this.circleRadiusKm = this.circleRadiusKm + 1
        this.circle.radius = this.circleRadiusKm * 1000
      }
    },
    updateCircleCenter() {
      if (this.circle) {
        const circleInstance = this.$refs.circle.$circleObject
        this.circle.center = circleInstance.getCenter().toJSON()
      }
    },
    updateCircleRadiusFromSlider() {
      this.circle.radius = this.circleRadiusKm * 1000
      this.circleRadiusKm = this.circle.radius / 1000
    },
    getAddress(place) {
      const address = {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: "",
        lng: "",
        suburb: "",
        url: "",
        formatted_address: "",
        radius: this.circle.radius,
      }
      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          address.state = x.long_name
        }
        if (x.types.includes("country")) {
          address.country = x.long_name
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          address.city = x.long_name
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          address.street = x.long_name
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes("postal_code")) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    generateTimeArray() {
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : `${i}`
        const timeObject = {
          type: `${hour}:00:00`,
          name:
            i === 0
              ? "12:00 AM"
              : i < 12
              ? `${i}:00 AM`
              : i === 12
              ? "12:00 PM"
              : `${i - 12}:00 PM`,
        }
        this.timeArray.push(timeObject)
      }
    },
    handleStripeAccount() {
      if (this.establishmentAccounts.length > 0) {
        if (!this.aliasAccount) {
          this.$swal({
            title: "Selecciona una cuenta",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
          return
        }
        this.createStoreAccount({
          store_account: {
            store_id: this.storeAccount,
            establishment_account_id: this.aliasAccount,
          },
        })
          .then((res) => {
            this.$swal({
              title: "Cuenta agregada!",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
            })
          })
          .catch((e) => {
            const errorMessage = e.response.data.messages
            this.$swal({
              title: errorMessage,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
          })
      } else {
        this.$refs.aliasRules.validate().then((success) => {
          if (success) {
            this.$swal({
              title: "Creando cuenta...",
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              },
            })
            this.createEstablishmentAccount({
              establishment_account: {
                establishment_id: this.$route.params.id,
                alias: this.aliasAccount,
                country: "MX",
                business_type: this.businessType,
              },
            })
              .then((res) => {
                this.createStoreAccount({
                  store_account: {
                    store_id: this.storeAccount,
                    establishment_account_id:
                      res.stripe_establishment_account.id,
                  },
                })
                  .then(() => this.$swal.close())
                  .catch((e) => {
                    const errorMessage = e.response.data.messages
                    this.$swal({
                      title: errorMessage,
                      icon: "error",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    })
                  })
                this.fetchEstablishmentAccountLink({
                  establishment_id: this.$route.params.id,
                  stripe_account_id:
                    res.stripe_establishment_account.stripe_account_id,
                }).then((res) => {
                  const url = res.account_link_url
                  window.location.href = url
                })
              })
              .catch((e) => {
                const message = e.response.data.messages
                this.$swal({
                  title: message,
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
      }
    },
    handleSubmit() {
      if (
        !this.store.address_attributes ||
        !this.store.address_attributes.lat
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Selecciona una dirección",
          },
        })
        return
      }

      this.store.accepted_payment_types = this.acceptedPaymentTypes

      this.store.delivery_radius =
        this.store.delivery_option === "delivery" ||
        this.store.delivery_option === "both"
          ? this.circle.radius
          : null

      this.addStore(this.store)
        .then((res) => {
          if (
            this.acceptedPaymentTypes.includes("bankcard") ||
            this.acceptedPaymentTypes.includes("bank_deposit")
          ) {
            this.storeAccount = res.id
            this.$swal({
              title: "Cargando cuentas...",
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              },
            })
            this.fetchEstablishmentAccounts({
              establishment_id: this.$route.params.id,
            })
              .then((res) => {
                this.$swal.close()
                this.showModal = true
              })
              .catch((res) => {
                this.$swal.close()
              })
          } else {
            // this.$router.push({ name: 'add-store-products', params: { id: response.id } })
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
            })
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Tienda agregada",
                icon: "CheckIcon",
                variant: "success",
                text: "Tu tienda ha sido agregada",
              },
            })
          }
        })
        .catch((e) => {
          const error = handleAPIErrors(e.response.data)

          this.$swal({
            title: error ?? "Error al agregar la tienda",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
    validateStoreInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.storeInfoRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateDeliveryOption() {
      return new Promise((resolve, reject) => {
        this.$refs.storeDeliveryRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateStorePayment() {
      return new Promise((resolve, reject) => {
        this.$refs.storePaymentRules.validate().then((success) => {
          if (success) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.radius__button {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0;
}
</style>

<styles lang="scss">
.custom-control {
  margin-bottom: 0.5rem;
}

.custom-control-primary {
  .custom-control-label {
    font-size: 1rem;
  }
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}

.pac-target-input {
  color: #495057;
  background-color: #fff;
  border: 1px solid #404656;

  padding: 0.438rem 1rem;
  border-radius: 0.357rem;
  width: 100%;
}

.dark-layout {
  .pac-target-input {
    color: #b4b7bd;
    background-color: #283046;
    border: 1px solid #404656;
  }
}
</styles>
